import { Controller } from "@hotwired/stimulus";
import { safeJSONParse } from "../utils/json_parsing";
import { get, post } from "@rails/request.js";

const FALSEY_PIPELINE_ID_VALUES = [null, undefined, "", -1, "-1"];

export default class extends Controller {
  static targets = [
    "allContent",
    "pipelineContent",
    "pipelineSelect",
    "pipelineStagesSelect",
    "pipelineAllPipelinesAndStages",
    "pipelineCurrentPermittedPipeline",
    "pipelineCurrentPermittedPipelineStages",
    "formTarget",
    "tagsInput",
    "recipientCountLink",
  ];

  static values = {
    recalculateRecipientsUrl: String,
    showRecipientModalUrl: String,
  };

  pipelineContentTargetConnected() {
    this.showFilterType();
    this.pipelineSelectTarget.addEventListener("change", this.recalculateRecipients.bind(this));
    this.tagsInputTarget.addEventListener("change", this.recalculateRecipients.bind(this));
  }

  showFilterType() {
    this.hideAllContent();
    this.handlePipelineFilter();
  }

  hideAllContent() {
    this.pipelineContentTarget.classList.add("hidden");
  }

  showContent(contentTarget) {
    contentTarget.classList.remove("hidden");
  }

  handlePipelineFilter() {
    this.setupPipelineSelect();
    if (!FALSEY_PIPELINE_ID_VALUES.includes(this.pipelineSelectTarget.value)) {
      // Value will be -1 for directory or null if unselected, falsey for both of those. Otherwise true for any valid pipeline ID.
      this.showContent(this.pipelineContentTarget);
    }
  }

  setupPipelineSelect() {
    this.showPipelineStages({ target: this.pipelineSelectTarget });
  }

  showPipelineStages(event) {
    if (event.target.value === -1 || event.target.value === "-1" || event.target.value === "") {
      // All Directory, hide stages and return
      this.pipelineContentTarget.classList.add("hidden");

      this.pipelineStagesSelectTarget.innerHTML = "";
      return;
    } else {
      this.pipelineContentTarget.classList.remove("hidden");
    }

    const selectedPipelineId = parseInt(event.target.value, 10);
    if (!selectedPipelineId) return;

    const pipelineStagesData = safeJSONParse(this.pipelineAllPipelinesAndStagesTarget.value, []);
    const pipeline = pipelineStagesData.find((p) => p.id === selectedPipelineId);
    const permittedStageIds = safeJSONParse(this.pipelineCurrentPermittedPipelineStagesTarget.value, []);

    if (pipeline) {
      this.pipelineStagesSelectTarget.innerHTML = pipeline.stages
        .map(
          (stage) => `
      <div class="flex items-center gap-2 py-2">
        <input id="stage-${stage.id}-id" type="checkbox"
               name="newsletter_issue_filter[permitted_stages][${stage.id}]"
               class="form-checkbox stage-checkbox"
               ${permittedStageIds.includes(stage.id) ? "checked" : ""}>
        <label for="stage-${stage.id}-id" class="form-label">${stage.name}</label>
      </div>
    `
        )
        .join("");
      // Find the newly created input method and add an event listener to it for recalculation
      this.pipelineStagesSelectTarget.querySelectorAll(".stage-checkbox").forEach((checkbox) => {
        checkbox.addEventListener("change", this.recalculateRecipients.bind(this));
      });
    } else {
      this.pipelineStagesSelectTarget.innerHTML = "";
    }
  }

  recalculateRecipients(event) {
    const payload = this.buildRecipientPayload();

    post(this.recalculateRecipientsUrlValue, {
      body: JSON.stringify(payload),
      responseKind: "turbo-stream",
    });
  }

  requestRecipientModal() {
    const payload = this.buildRecipientPayload();
    let queryParams = new URLSearchParams();

    Object.keys(payload).forEach((key) => {
      let value = payload[key];
      if (Array.isArray(value)) {
        value.forEach((item) => queryParams.append(`${key}[]`, item));
      } else {
        queryParams.append(key, value);
      }
    });

    console.log("request recipeint modal");

    get(`${this.showRecipientModalUrlValue}?${queryParams.toString()}`, { responseKind: "turbo-stream" });
  }

  buildRecipientPayload() {
    const form = document.querySelector("#newsletter_content_form");
    const formData = new FormData(form);

    const filterGroupType = formData.get("[newsletter_issue_filter][filter_group_type]");
    const tags = formData.getAll("[newsletter_issue_filter][tags]");
    const permittedStages = [];
    for (const entry of formData.entries()) {
      if (entry[0].startsWith("newsletter_issue_filter[permitted_stages]")) {
        permittedStages.push(entry[0].split("[")[2].split("]")[0]);
      }
    }

    return {
      filter_group_type: filterGroupType,
      tags: tags,
      permitted_stages: permittedStages,
    };
  }
}
